import React from "react";
import Col from "react-bootstrap/Col";

const SliderImgBottom = (props, ref) => {
  const { img, alt, number, description, description2, title, url } = props;
  return (
    <Col xl={3} id="slideDivRec" ref={ref}>
      <div className="divbox">
        <div className="SliderTitleDiv">
          <span className="number">{number}</span>
          <p className="description">
            {description} {description2}
          </p>
          <p className="SliderTitle">{title}</p>
        </div>
      </div>
      <div className="divbox imageDiv">
        <a href={url} target="_blank"  rel="noreferrer">
          <img src={img} alt={alt} />
          </a>
      </div>
    </Col>
  );
};

export default React.forwardRef(SliderImgBottom);
