import React from "react";
import { motion, AnimatePresence } from "framer-motion";
const ExTab2 = () => {
  const variants = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 3,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 3,
        staggerChildren: 0.3,
      },
    },
  };

  return (
    <>
      <AnimatePresence>
        <motion.div
          className="exInnerDivRight"
          variants={variants}
          key="introDiv"
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0 }}
        >
          <ul>
          <li>
            Demonstrated expertise in a wide array of technologies, including HTML and CSS for email templates, JavaScript, React, PHP, MySQL, IIS, Microsoft Azure (CRM), WordPress, and Laravel, to deliver comprehensive and dynamic web solutions.
            </li>
            <li>
            Designed and developed bespoke WordPress websites, crafting custom themes from scratch and seamlessly integrating plugins to address specific organizational needs and enhance functionality.
            </li>
            <li>
            Implemented robust client-side form validation using JavaScript to ensure data accuracy and integrity, coupled with efficient handling of asynchronous form submissions through fetch API and Axios to streamline data retrieval and optimize processing times.
            </li>
         
            <li>
            Conducted thorough testing and debugging to identify and resolve issues, ensuring functionality and responsiveness across diverse devices and platforms, and validated code to maintain high-quality standards.
            </li>
            <li>
            Utilized advanced development tools and workflows, including Git bash terminal for version control, GitHub for collaborative code management, Node.js for terminal management, and Jira for project tracking and task management.
            </li>

            <li>
              Conducted testing and debugging to resolve any issues and improve
              functionality. Tested and validated code to ensure responsive
              design.
            </li>
            <li>
              Utilized: Git bash terminal, Github management workflow Node.JS
              terminal management and Jira.
            </li>
          </ul>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default ExTab2;
