import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainNav from "../MainSiteNav/MainNav";
import "../Contact/Contact.css";
import { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { SocialIcon } from "react-social-icons";
const Contact = () => {
  const form = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [btnHover, setBtnHover] = useState(false);
  const paperPlane = <FontAwesomeIcon icon={faPaperPlane} />;

  const socialNetworks = [
    { url: "https://www.facebook.com/Lou.Carter.Jr/", network: "facebook" },
    { url: "https://github.com/Babyhacker36", network: "github" },
    { url: "https://www.linkedin.com/in/louiscarterjr/", network: "linkedin" },
    { url: "https://twitter.com/Louiscarterjr21", network: "twitter" },
  ];

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_0g8l7p7",
        "template_ehkljno",
        form.current,
        "6TSp_ZUowMmDJDFlh"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
          setIsVisible(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    if (isVisible) {
      const timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [isVisible]);

  return (
    <Container fluid className="containerBody contactBody">
      <MainNav />
      <Row className="storyBodyRow ContactRow">
        <div
          style={{ display: isVisible ? "block" : "none" }}
          className="Message"
        >
          <p> I Got It. Thanks!!!</p>
          <p> Message Sent</p>
        </div>

        <Col className="contactHeader">
          <h1 className="glow">CONTACT ME</h1>
        </Col>

        <Col md={8} className="formCol">
          <div className="formDivContain">
            <form ref={form} onSubmit={sendEmail} className="contactForm">
              <input
                type="text"
                name="user_name"
                placeholder="Name"
                class="no-outline"
              />

              <input
                type="email"
                name="user_email"
                placeholder="Email"
                class="no-outline"
              />

              <textarea
                name="message"
                placeholder="Leave Me a Message"
                class="no-outline"
              />

              {/* <input type="submit" value='Send' /> */}
              <button
                type="submit"
                onMouseEnter={() => {
                  setBtnHover(true);
                }}
                onMouseLeave={() => {
                  setBtnHover(false);
                }}
              >
                {btnHover ? "SEND IT!" : paperPlane}
              </button>
            </form>
            <div className="socialMediaCol">
              {socialNetworks.map((network, index) => (
                <SocialIcon
                  key={index}
                  url={network.url}
                  network={network.network}
                  bgColor="transparent"
                  fgColor="#81f7ff"
                  style={{ height: 60, width: 60 }}
                />
              ))}
            </div>
          </div>
        </Col>


      </Row>
    </Container>
  );
};

export default Contact;
