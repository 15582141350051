import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import LouPortImg from "../../images/ExPagePics/portLou.jpg";
const ExTab1 = () => {
  const variants = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 3,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 3,
        staggerChildren: 0.3,
      },
    },
  };

  return (
    <>
      <AnimatePresence>
        <motion.div
          className="exInnerDivRight"
          variants={variants}
          key="introDiv"
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0 }}
        >
          
          <p>
          As a Full-Stack Web Developer and Air Force veteran with over 8 years of experience, I specialize in front-end and back-end programming, responsive frameworks, and industry coding practices, web marketing and project management.</p>
          <img src={LouPortImg} className="louPort" alt="louiscarterjr" />
          <a className="view-resume" href="https://docs.google.com/document/d/1QA1BH0gex7TSX_rI4hZzbDQYN4ZeukJm/edit?usp=sharing&ouid=103029662009694315294&rtpof=true&sd=true" target="_blank"  rel="noreferrer">
            View My Resume
          </a>
        
          <ul>
            <li>
            Front-End Technologies: JavaScript, React.js, jQuery, JSON Bootstrap, HTML5, CSS, SCSS, TypeScript
            </li>
            <li>
            Back-End Technologies: PHP, Node.js, Laravel, API's
            </li>
            <li>
            Database Management: MySQL, MongoDB, Azure
            </li>
            <li>
            Web Development Tools: Git, GitHub, Jira, Microsoft Azure, IIS
            </li>
            <li>
            Analytics and SEO: Google Analytics, Google Business
            </li>
          
          </ul>
      
        
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default ExTab1;
