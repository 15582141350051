import React from "react";
import { motion, AnimatePresence } from "framer-motion";
const ExTab3 = () => {
  const variants = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 3,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 3,
        staggerChildren: 0.3,
      },
    },
  };

  return (
    <>
      <AnimatePresence>
        <motion.div
          className="exInnerDivRight"
          variants={variants}
          key="introDiv"
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0 }}
        >
          <ul>
            <li>
            Developed sophisticated, responsive web applications utilizing cutting-edge front-end frameworks including React.js, JavaScript, jQuery, CSS, and HTML, while ensuring mobile responsiveness and leveraging Bootstrap for consistent, adaptive design. Managed React file structures meticulously to guarantee seamless front-end integration and performance.
            </li>
            <li>
            Orchestrated cross-functional team collaborations, working seamlessly with designers, product managers, and QA engineers to deliver high-caliber, innovative web solutions that meet and exceed project requirements.
            </li>
            <li>
            Architected and implemented robust RESTful APIs to enable efficient data exchange, coupled with advanced client-side validation using JavaScript and JSON, and optimized form processing through fetch API and Axios for superior application responsiveness and reliability.
            </li>
            <li>
            Configured and deployed scalable server-side applications on Microsoft Azure, harnessing the power of Node.js, EC2, and S3 to optimize cloud-based operations and enhance application infrastructure.
            </li>
            <li>
            Administered version control and collaborative development practices through Git, GitHub, and GitLab, ensuring streamlined project workflows, enhanced code integrity, and effective team collaboration.
            </li>
            <li>
            Integrated and optimized Google Analytics and Tag Manager, meticulously tracking user behavior, customizing events, and synchronizing data with CRM systems to provide actionable insights and drive strategic decision-making.
            </li>
          </ul>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default ExTab3;
