import React from "react";
import { Container, Row } from "react-bootstrap";
import MainNav from "../MainSiteNav/MainNav";
import "../Story/Story.css";
import MeImage from "../../images/StoryPics/me3.png";
import HackerMask from "../../images/StoryPics/hackermask.jpg";
import { motion } from "framer-motion";

const StoryPage = () => {
  return (
    <Container fluid className="containerBody storyBody">
      <MainNav />
      <Row className="storyBodyRow">
        <motion.div
          className="col-xl-5 col-xxl-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3 }}
        >
          <div className="card">
            <div className="cardImgDiv">
              <img src={MeImage} alt="Louis Carter" />
            </div>
          </div>
          <div className="textDiv">
            <div className="textDivInner">
              <p>
                As a React developer, I have a passion for creating dynamic and intuitive user interfaces. I find joy in problem-solving and the challenge of taking complex ideas and turning them into a seamless user experience. My passion runs deep.
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          className="col-xl-5 col-xxl-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3, delay: 1 }}
        >
          <div className="card">
            <div className="cardImgDiv">
              <img src={HackerMask} alt="Louis Carter" />
            </div>
          </div>
          <div className="textDiv">
            <div className="textDivInner">
              <p>
                I am always striving to improve my skills and knowledge of the latest technologies in the industry. I am deeply in love with programming Front / Back end, as it allows me to bring my ideas to life and have a tangible impact on the world.
              </p>
            </div>
          </div>
        </motion.div>
      </Row>
    </Container>
  );
};

export default StoryPage;
