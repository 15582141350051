import React from "react";
import { Row } from "react-bootstrap";
import { useRef } from "react";
import thermon from "../../images/SlidePics/Thermon.jpg";
import wbsd from "../../images/SlidePics/wbsd.jpg";
import louflix from "../../images/SlidePics/louflix.jpg";
import vrSpotlight from "../../images/SlidePics/vrspotlight.jpg";
import thermoncal from "../../images/SlidePics/thermoncal.jpg";
import linkinpark from "../../images/SlidePics/linkinpark.jpg";
import louisgiffy from "../../images/SlidePics/louisgiffy.jpg";
import luminareHealth from "../../images/SlidePics/luminarehealth.jpg";
import SliderImgTop from "./SliderImgTop";
import SliderImgBottom from "./SliderImgBottom";

const DesignsSlider = () => {
  const firstElement = useRef(null);
  const lastElement = useRef(null);

  const handleScrollRight = () => {
    lastElement.current.scrollIntoView({ block: "end", behavior: "smooth" });
  };

  const handleScrollLeft = () => {
    firstElement.current.scrollIntoView({ block: "end", behavior: "smooth" });
  };

  // modal

  return (
    <Row id="horizontalScroller">
      <button
        type="button"
        id="slideButtonL"
        onClick={() => {
          handleScrollLeft();
        }}
      >
        <span>&#x3c;</span>
      </button>
      <SliderImgTop
        img={thermon}
        alt="Thermon"
        number="01"
        description="Custom WP Theme - Javascript, HTML,"
        description2=" CSS, PHP, MySQl, API"
        title="THERMON INDUSTRIES"
        ref={firstElement}
        url="https://www.thermon.com/"
      />

      <SliderImgBottom
        img={wbsd}
        alt="WB Southern Drilling"
        number="02"
        description="Parallex Website"
        description2="Created With Wordpress"
        title="WB SOUTH. DRILLING"
        url="https://wbsoutherndrillingtx.com/"
      />

      <SliderImgTop
        img={louflix}
        alt="LouFlix"
        number="03"
        description="Api Movie Search App"
        description2="API, Javascript, HTMl, CSS"
        title="LOUFLIX MOVIE'S"
        url="https://louiscarterjr.com/louflix/"
      />

      <SliderImgBottom
        img={luminareHealth}
        alt="Luminare Health"
        number="04"
        description="React.JS, Azure, SQL,"
        description2="Bootstrap, HTML, CSS, Javascript"
        title="Luminare Health"
        url="https://www.luminarehealth.com/"
      />

      <SliderImgTop
         img={vrSpotlight}
         alt="VR Technonology"
         number="04"
         description=" VR Technology Show Case"
         description2="Bootstrap, HTML, CSS, Javascript"
         title="VIRTUAL REALITY APP"
         url="https://louiscarterjr.com/vrspotlight/"
      />

      <SliderImgBottom
        img={thermoncal}
        alt="Javascript Calculator"
        number="06"
        description="Javascript Calculator"
        description2="Javascript, PHP, Bootstrap, HTML, CSS"
        title="POWER SOLUTIONS CAL"
        url="https://thermon.com/resources_ext/led_cal_usa/"
      />

      <SliderImgTop
        img={linkinpark}
        alt="Linkin Park"
        number="07"
        description="Creative Music Website"
        description2="Javascript, Bootstrap, HTML, CSS"
        title="LINKIN PARK TRIBUTE"
        url="http://louiscarterjr.com/linkinpark/"
      />

      <SliderImgBottom
        img={louisgiffy}
        alt="Lou's Giffy"
        number="08"
        description=" Giffy API Application"
        description2="Bootstrap, JavaScript, HTML, CSS"
        title="LOUS GIFFY"
        ref={lastElement}
        url="https://louiscarterjr.com/lousgiffy/"
      />
      <button
        type="button"
        id="slideButtonR"
        onClick={() => {
          handleScrollRight();
        }}
      >
        <span>&#62;</span>
      </button>
    </Row>
  );
};

export default DesignsSlider;
