import React from "react";
import MainNav from "../MainSiteNav/MainNav";
import Container from "react-bootstrap/Container";
import "../Designs/Designs.css";
import DesignHeader from "./DesignHeader";
import DesignsSlider from "./DesignsSlider";


const DesignsPage = () => {
  return (
    <>
      <Container fluid className="containerBody designsContainer">
        <MainNav />
        <DesignHeader />
      </Container>
      <Container fluid className="slideContainer">
        <DesignsSlider />
      </Container>
    </>
  );
};

export default DesignsPage;
