import React from 'react'

const SpeechDiv = () => {
  return (
    <> 
      <div id='speech_bubble'>
          <div>
              <p className='speech_text speech_typewriter'>Welcome To My Site!</p>
              <p className='speech_text speech_typewriter2'> My name is Lou.</p>
          </div>
    </div>

    </>
  )
}

export default SpeechDiv