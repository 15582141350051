import React from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import VideoPPVIP from "../src/images/ExPagePics/PPVIPVideo.webm";
import { Link } from "react-router-dom";
import MainNav from "./Components/MainSiteNav/MainNav";

const VideoPlayer = () => {
  return (
    <>
      <MainNav />
      <div className="VideoPlayerDiv">
        <div className="vidPlayer">
          <Video
            autoPlay
            loop
            muted
            controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
            poster="../src/images/SlidePics/ppvip.jpg"
            onCanPlayThrough={() => {
              // Do stuff
            }}
          >
            <source src={VideoPPVIP} type="video/webm" />
            <track
              label="English"
              kind="subtitles"
              srcLang="en"
              src="http://source.vtt"
              default
            />
          </Video>
        </div>
        <div className="BacktoDesigns">
          <Link to="/Designs">
            <p>&larr; Back to Designs</p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default VideoPlayer;
