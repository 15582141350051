import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from "./Components/Home/HomePage";
import DesignsPage from './Components/Designs/DesignsPage';
import ExperiencePage from './Components/Experience/ExperiencePage';
import StoryPage from './Components/Story/StoryPage';
import ContactPage from "./Components/Contact/ContactPage"
import { Route, Routes  } from 'react-router-dom'
import { Container } from "react-bootstrap";
import './App.css';
import VideoPlayer from './VideoPlayer';
import Test from './Test';

function App() {
  return (
    <Container fluid className='mainContainer'> 
    <Routes>  
        <Route path="/" element={<HomePage />} />
        <Route path="/Designs" element={<DesignsPage />} />
        <Route path="/Experience" element={<ExperiencePage />} />
        <Route path="/Story" element={<StoryPage />} />
        <Route path="/Contact" element={<ContactPage />} />
        <Route path="/PPVIPVideo" element={<VideoPlayer />} />
        <Route path="/Test" element={<Test />} />

    </Routes>
    </Container>
  )
}

export default App;
