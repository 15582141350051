import React, { useState, useEffect } from "react";
import { Row, Col, Offcanvas } from "react-bootstrap";
import { Divide as Hamburger } from "hamburger-react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../../mobileStyle.css";
import MobileSMCanvas from "./MobileSMCanvas";
import BrandLogo from "../../images/Logo.png";

const MainNav = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  const links = [
    { path: "/Designs", name: "Designs" },
    { path: "/Experience", name: "Career" },
    { path: "/Story", name: "Story" },
    { path: "/Contact", name: "Contact" },
  ];

  return (
    <>
      <Row className="siteNavRow">
        <Col xs={9} md={5} xl={7} className="logoBrandCol">
          <Link to="/">
            <img src={BrandLogo} alt="" className="brandLogo" /> Louis Carter Jr
          </Link>
        </Col>
        <Col xs={3} md={7} xl={5} className="siteNavCol">
          <ul>
            {links.map((link) => (
              <li
                key={link.path}
                className={activeTab === link.path ? "active" : ""}
              >
                <Link to={link.path}>{link.name}</Link>
              </li>
            ))}
          </ul>
        </Col>
        <Col className="HamMenuCol" xs={3} md={7} onClick={handleShow}>
          <Hamburger
            className="testme"
            rounded
            color="#81f7ff"
            duration={0.4}
            easing="ease-in"
            size={60}
            toggled={show}
            toggle={setShow}
            hideOutline={false}
          />
        </Col>
      </Row>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header>
          <Offcanvas.Title>
            <MobileSMCanvas />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Col className="MobileMenuCanvas">
            <ul>
              {links.map((link) => (
                <li
                  key={link.path}
                  className={activeTab === link.path ? "active" : ""}
                >
                  <Link to={link.path}>{link.name}</Link>
                </li>
              ))}
            </ul>
          </Col>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default MainNav;
