import React from "react";
import { motion, AnimatePresence } from "framer-motion";
const ExTab4 = () => {
  const variants = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 3,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 3,
        staggerChildren: 0.3,
      },
    },
  };

  return (
    <>
      <AnimatePresence>
        <motion.div
          className="exInnerDivRight"
          variants={variants}
          key="introDiv"
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0 }}
        >
          <ul>
            <li>
            Developed and delivered dynamic curriculum for high school students, effectively introducing foundational concepts in web development and fostering early engagement with technology.
            </li>
            <li>
            Designed and implemented comprehensive coursework covering essential programming languages and markup technologies including HTML5, CSS, Bootstrap, and JavaScript, to build a strong technical foundation.
            </li>

            <li>
            Instructed students in a diverse range of coding languages and technologies, including JavaScript, HTML, CSS, MySQL, and MongoDB, empowering them with practical skills for modern web development.
            </li>
            <li>
            Guided students through complex coding projects, promoting critical thinking, problem-solving, and skill development through hands-on challenges and real-world scenarios.
            </li>

            <li>
            Collaborated with educational staff to create and deploy a robotics course, leveraging the Brain.JS framework to integrate advanced coding concepts and enhance the learning experience.
            </li>

            <li>
            Maintained up-to-date knowledge of coding best practices and emerging trends, continuously seeking opportunities to refine and elevate the coding camp curriculum and overall educational experience.
            </li>
          </ul>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default ExTab4;
